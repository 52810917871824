/* eslint-disable */

/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Drivers Portal (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import { LoadingOutlined } from '@ant-design/icons';
import { CCard, CCardBody, CCardHeader } from '@coreui/react';
import { Button, Form, Modal, Pagination, Select, Spin, Switch, Table, Upload, message, notification } from 'antd';
import { City, State } from 'country-state-city';
import React, { useEffect, useState } from 'react';

import { _add_city, _add_state, _delete_city, _delete_state, _edit_city_status, _get_all_state, _get_city, _get_state } from "common/axios_services";
import { _get_profile } from "common/axios_services";

function CityInfo() {
    const [form] = Form.useForm();
    const [page, setPage] = useState(1);
    const [total_categories, setTotal_categories] = useState(0);
    const [page_size, setPage_size] = useState(10);
    const [spinnerLoading, setSpinnerLoading] = useState(false);
    const [updateState, setUpdateState] = useState(0);
    const [allProducts, setAllProducts] = useState('city');
    const [allCities, setAllCities] = useState([]);
    const [allStates, setAllStates] = useState([]);
    const [fetchingData, setFetchingData] = useState(true);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [addingCity, setAddingCity] = useState(false);
    const [selectedCityId, setSelectedCityId] = useState("");
    const [stateCode, setStateCode] = useState("");
    const [countryCode, setCountryCode] = useState("");
    const [error_msg, setError_msg] = useState("")
    const [updateCount, setUpdateCount] = useState(1)
    const [addCities, setAddCities] = useState(false)
    const [deleteCities, setDeleteCities] = useState(false)

    // Functions to handle
    const openNotificationWithIcon = (type, message) => {
        notification[type]({
            message: '',
            placement: 'bottomRight',
            description:
                message,
        });
    };

    const fetchCities = async () => {
        try {
            const uploadedCities = await _get_city({ page, page_size })
            setFetchingData(false);
            setAllCities(uploadedCities.data.data)
            setTotal_categories(uploadedCities.data.meta.total)
        } catch (err) {
            setFetchingData(false);
            if (err.response) {
                openNotificationWithIcon('error', err.response.data.message)
            } else {
                openNotificationWithIcon('error', err.message)
            }
        }
    }
    const fetchAddedStates = async () => {
        try {
            const uploadedStates = await _get_all_state()
            setFetchingData(false);
            setAllStates(uploadedStates.data.data)
        } catch (err) {
            setFetchingData(false);
            if (err.response) {
                openNotificationWithIcon('error', err.response.data.message)
            } else {
                openNotificationWithIcon('error', err.message)
            }
        }
    }
    const fetchUser = async () => {
        const user_profile = await _get_profile()
        if (user_profile.data.status == "success") {
            if (user_profile.data.data.role.role_access.includes("addCities")) {
                setAddCities(true)
            } else {
                setAddCities(false)
            }
            if (user_profile.data.data.role.role_access.includes("deleteCities")) {
                setDeleteCities(true)
            } else {
                setDeleteCities(false)
            }
        } else {
            setDeleteCities(false)
            setAddCities(false)
        }
    }
    useEffect(() => {
        fetchCities();
        fetchAddedStates();
        fetchUser()
    }, [page, page_size, allProducts, updateState, updateCount])
    const changeCityVals = (values) => {
        if (values.state) {
            setStateCode(values.state.value)
            setCountryCode(values.state.title)
            form.setFieldsValue({
                city: "",
            });
        }
    }

    const addCity = async (values) => {
        setAddingCity(true)
        try {
            const data = {
                state_id: values.state.key,
                city_name: values.city.label,
                state_code: values.state.value,
                longitude: values.city.title,
                latitude: values.city.key,
            }
            const postCity = await _add_city(data)
            setAddingCity(false)
            openNotificationWithIcon('success', postCity.data.message)
            setAllProducts('city')
        } catch (err) {
            setAddingCity(false)
            if (err.response) {
                setError_msg(err.response.data.message)
            } else {
                setError_msg(err.message)
            }
        }
    };

    const deleteCategory = (e) => {
        e.preventDefault();
        setIsModalVisible(true);
    }

    const finaliseStateRemoval = async () => {
        setSpinnerLoading(true)
        try {
            const deleteCity = await _delete_city({ id: selectedCityId })
            openNotificationWithIcon('success', deleteCity.data.message);
            setUpdateState(updateState + 1)
            setSpinnerLoading(false)
            setIsModalVisible(false)
        } catch (err) {
            setSpinnerLoading(false)
            if (err.response) {
                openNotificationWithIcon('error', err.response.data.message)
            } else {
                openNotificationWithIcon('error', err.message)
            }
        }
    }

    const change_page = (selected_page, pageSize) => {
        setPage(selected_page);
        setPage_size(pageSize);
    }
    const handleOk = () => {
        setIsModalVisible(false);
    };
    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const changeStatus = async ({ value, id }) => {
        try {
            const editStatus = await _edit_city_status({ id, status: value })
            openNotificationWithIcon('success', editStatus.data.message)
            setUpdateCount(updateCount + 1)
        } catch (err) {
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    windows.location.reload()
                    openNotificationWithIcon('error', err.response.data.message)
                } else {
                    openNotificationWithIcon('error', err.response.data.message)
                }
            } else {
                openNotificationWithIcon('error', err.message)
            }
        }
    }

    let data = allCities.map((city, index) => {
        return {
            key: index,
            id: index + 1,
            name: city.city_name,
            state_code: city.state_code,
            dis_status: <Form form={form} initialValues={{ display_status: city.status }}><Form.Item name="display_status" valuePropName="checked">{city.status ? <Button onClick={() => changeStatus({ value: false, id: city.id })}>Deactivate</Button> : <Button onClick={() => changeStatus({ value: true, id: city.id })}>Activate</Button>}</Form.Item></Form>,
            actions: <div>{deleteCities && <form className="inline-form"
                onSubmit={e => { deleteCategory(e); setSelectedCityId(city.id) }}>
                <input type="hidden" value={city.id} name="categoryId" />
                <Button type="link" htmlType="submit" className="inline-button">Delete</Button></form>}</div>
        }
    });
    const columns = [
        {
            title: 'S/N',
            dataIndex: 'id',
            className: 'column-number',
            align: 'center',
        },
        {
            title: 'City',
            dataIndex: 'name',
            render: text => <a>{text}</a>,
        },
        {
            title: 'State Code',
            className: 'column-number',
            dataIndex: 'state_code',
            align: 'center',
        },
        {
            title: 'Display Status',
            className: 'column-number',
            dataIndex: 'dis_status',
            align: 'center',
        },
        {
            title: 'Actions',
            className: 'column-number',
            dataIndex: 'actions',
            align: 'center',
        }
    ];
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox mx={2} mt={-3} py={3} px={2} variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="info" >
                                <MDTypography variant="h6" color="white">
                                    Delivery settings
                                </MDTypography>
                            </MDBox>
                            <MDBox py={3} px={2}>
                                {allProducts === 'city' &&
                                    <Spin tip="Loading..." spinning={spinnerLoading}>
                                        <React.Fragment>
                                            <CCard>
                                                <CCardHeader>
                                                    <div className="grid-flex">
                                                        List of Cities
                                                        <div>
                                                            {addCities &&
                                                                <Button type="primary" onClick={() => setAllProducts('add-city')}>Add City</Button>
                                                            }
                                                        </div>
                                                    </div>
                                                </CCardHeader>
                                                <CCardBody>
                                                    {
                                                        fetchingData ?
                                                            <div>
                                                                <Spin indicator={antIcon} />
                                                            </div> :
                                                            <>
                                                                <Table columns={columns} pagination={false} dataSource={data} bordered />
                                                                <div style={{ marginTop: "2%" }}></div>
                                                                <Pagination showSizeChanger current={page} total={total_categories} showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} state(s)`} defaultPageSize={page_size} onChange={change_page} />
                                                            </>
                                                    }
                                                </CCardBody>
                                            </CCard>
                                        </React.Fragment>
                                        <Modal title="Delete City" open={isModalVisible} onOk={handleOk} onCancel={handleCancel} footer={null}>
                                            <p>Are you sure you want to delete this City?</p>
                                            <div>
                                                <button onClick={() => setIsModalVisible(false)} style={{ border: '1px solid #F0F0F0', borderRadius: 4, padding: '10px 25px', background: 'transparent', marginRight: 10, fontSize: 12, fontWeight: '600' }}
                                                >Cancel</button>
                                                <button onClick={() => finaliseStateRemoval()} style={{ border: '1px solid #FF4A00', borderRadius: 4, padding: '10px 25px', background: '#FF4A00', fontSize: 12, fontWeight: '600', color: '#fff' }}
                                                >Delete</button>
                                            </div>
                                        </Modal>
                                    </Spin>
                                }
                                {allProducts === 'add-city' &&
                                    <React.Fragment>
                                        <CCard>
                                            <CCardHeader>
                                                <div className="grid-flex">
                                                    Add City
                                                    <div>
                                                        <Button type="primary" onClick={() => setAllProducts('city')}>View city</Button>
                                                    </div>
                                                </div>
                                            </CCardHeader>
                                            <CCardBody>
                                                {error_msg && (<p style={{ color: "red" }}>{error_msg}</p>)}
                                                <Form form={form} onValuesChange={changeCityVals} onFinish={addCity} layout="vertical">
                                                    <Form.Item name="state" label="State">
                                                        {allStates ?
                                                            <Select placeholder="Select State" labelInValue>
                                                                {allStates.map((state) => (
                                                                    <Select.Option key={state.id} title={state.country_code} value={state.state_code}>{state.name}</Select.Option>
                                                                ))}
                                                            </Select> :
                                                            <Select placeholder="Select State"></Select>
                                                        }
                                                    </Form.Item>
                                                    <Form.Item name="city" label="City Name" >
                                                        {stateCode && countryCode ? (
                                                            <Select placeholder="Select City" labelInValue>
                                                                {City.getCitiesOfState(countryCode, stateCode).map((city) => (
                                                                    <Select.Option key={city.latitude} title={city.longitude} value={city.isoCode}>{city.name}</Select.Option>
                                                                ))}
                                                            </Select>
                                                        ) : (
                                                            <Select placeholder="Select City"></Select>
                                                        )}
                                                    </Form.Item>
                                                    <Form.Item>
                                                        {addingCity ?
                                                            <Button disabled loading type="primary">Submitting....</Button>
                                                            : <Button htmlType="submit" type="primary">Submit</Button>
                                                        }
                                                    </Form.Item>
                                                </Form>
                                            </CCardBody>
                                        </CCard>
                                    </React.Fragment>
                                }
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout >
    );

};

export default CityInfo;
